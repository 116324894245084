/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 21, 2023 */

@font-face {
  font-family: "neue_haas_grotesk_display65Md";
  src: url("../fontfamily/neuehaasdisplaymediu-webfont.eot");
  src: url("../fontfamily/neuehaasdisplaymediu-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fontfamily/neuehaasdisplaymediu-webfont.woff2") format("woff2"),
    url("../fontfamily/neuehaasdisplaymediu-webfont.woff") format("woff"),
    url("../fontfamily/neuehaasdisplaymediu-webfont.ttf") format("truetype"),
    url("../fontfamily/neuehaasdisplaymediu-webfont.svg#neue_haas_grotesk_display65Md")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'neue_haas_grotesk_disp55roman';
  src: url('../fontfamily/neuehaasdisplayroman-webfont.eot');
  src: url('../fontfamily/neuehaasdisplayroman-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fontfamily/neuehaasdisplayroman-webfont.woff2') format('woff2'),
       url('../fontfamily/neuehaasdisplayroman-webfont.woff') format('woff'),
       url('../fontfamily/neuehaasdisplayroman-webfont.ttf') format('truetype'),
       url('../fontfamily/neuehaasdisplayroman-webfont.svg#neue_haas_grotesk_disp55roman') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'neue_haas_grotesk_display45Lt';
  src: url('../fontfamily/neuehaasdisplaylight-webfont.eot');
  src: url('../fontfamily/neuehaasdisplaylight-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fontfamily/neuehaasdisplaylight-webfont.woff2') format('woff2'),
       url('../fontfamily/neuehaasdisplaylight-webfont.woff') format('woff'),
       url('../fontfamily/neuehaasdisplaylight-webfont.ttf') format('truetype'),
       url('../fontfamily/neuehaasdisplaylight-webfont.svg#neue_haas_grotesk_display45Lt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neue_haas_grotesk_display35Th';
  src: url('../fontfamily/neuehaasdisplaythin-webfont.eot');
  src: url('../fontfamily/neuehaasdisplaythin-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fontfamily/neuehaasdisplaythin-webfont.woff2') format('woff2'),
       url('../fontfamily/neuehaasdisplaythin-webfont.woff') format('woff'),
       url('../fontfamily/neuehaasdisplaythin-webfont.ttf') format('truetype'),
       url('../fontfamily/neuehaasdisplaythin-webfont.svg#neue_haas_grotesk_display35Th') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'neue_haas_grotesk_di56romanIt';
  src: url('../fontfamily/neuehaasdisplayromanitalic-webfont.eot');
  src: url('../fontfamily/neuehaasdisplayromanitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fontfamily/neuehaasdisplayromanitalic-webfont.woff2') format('woff2'),
       url('../fontfamily/neuehaasdisplayromanitalic-webfont.woff') format('woff'),
       url('../fontfamily/neuehaasdisplayromanitalic-webfont.ttf') format('truetype'),
       url('../fontfamily/neuehaasdisplayromanitalic-webfont.svg#neue_haas_grotesk_di56romanIt') format('svg');
  font-weight: normal;
  font-style: normal;

}