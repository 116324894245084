/* Start Properties Portion CSS Code Here */
.properties_box .p_heading {
    color: #141414;
    font-family: "neue_haas_grotesk_display65Md";
    margin-bottom: 30px;
}

.properties_box .card {
    border-color: transparent;
    cursor: pointer;
}

.properties_box .card .card_img {
    position: relative;
}

.properties_box .card .card_img .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #14141482;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
}

.properties_box .card .card_img .overlay h4 {
    color: white;
    font-family: 'neue_haas_grotesk_disp55roman';
    max-width: 150px;
    text-transform: uppercase;
}

.properties_box .card:hover .overlay {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.properties_box .card .card-text {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #4a4a4a;
    display: -webkit-box !important;
    overflow: hidden;
    font-family: 'neue_haas_grotesk_disp55roman';
}

.properties_box .card .card-body {
    padding: 0;
    padding-top: 30px;
}

.know_more_btn {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: transparent;
    color: #141414;
    border-radius: 0;
    border-color: #141414;
    position: relative;
    font-family: "neue_haas_grotesk_display65Md";
    text-transform: uppercase;
    font-size: 14px;
    text-align: left;
    width: 178px;
    padding: 4px 0.75rem;
    margin-top: 30px;
}

.know_more_btn span {
    height: 6px;
    width: 6px;
    border-radius: 100%;
    position: absolute;
    top: 42%;
    left: -3px;
    background-color: #141414;
}

.know_more_btn {
    padding: 4px 2rem;

}

.know_more_btn:hover {
    font-size: 15px;
}

.know_more_btn:hover,
.know_more_btn:focus,
.know_more_btn:active,
.know_more_btn:focus-visible {
    background-color: transparent !important;
    color: #141414 !important;
    border-color: #141414 !important;
    box-shadow: none !important;
    outline: 0;
}

/* End Properties Portion CSS Code Here */