/* Start Properties Detail Portion CSS Code Here */
.properties_detail_box {
  margin-top: 180px;
  margin-bottom: 140px;
}

.properties_detail_box .center {
  margin: 30px 0px;
}

.properties_detail_box h3 {
  font-family: 'neue_haas_grotesk_disp55roman';
  color: #141414;
}

.properties_detail_box .hospitalitylogo {
  height: 150px;
  width: 150px;
  margin: auto;
  border-radius: 100%;
  animation: circle 15s linear infinite;
}

.properties_detail_box .hospitalitylogo img {
  width: 100%;
}

.properties_detail_box .circle_animate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* End Properties Detail Portion CSS Code Here */


/* Start Residential Food Forest Portion CSS Code Here */
.residential_food_forest {
  margin-bottom: 140px;
}

.residential_food_forest .residential_heading h1 {
  color: #141414;
  font-family: "neue_haas_grotesk_display65Md";
}

.residential_food_forest .residential_heading {
  margin-bottom: 30px;
}

.residential_food_forest h4 {
  font-family: 'neue_haas_grotesk_disp55roman';
  color: #141414;
  padding-right: 30px;
}

.residential_food_forest p {
  font-family: 'neue_haas_grotesk_disp55roman';
  color: #141414;
  padding-right: 100px;
}

.residential_food_forest img {
  margin-bottom: 50px;
}

/* End Residential Food Forest Portion CSS Code Here */

/* Start Community Welfare Center Portion CSS Code Here */
.community_welfare_center {
  margin-bottom: 140px;
}

.residential_food_forest .owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 30px;
}

.residential_food_forest .owl-theme .owl-dots .owl-dot span {
  background-color: #1414148f;
}

.residential_food_forest .owl-carousel {
  margin-bottom: 50px;
}

.residential_food_forest img {
  margin-bottom: 0;
  width: 100%;
}

.residential_food_forest .owl-theme .owl-dots .owl-dot.active span {
  background-color: #141414;
}


.community_welfare_center .community_heading h1 {
  color: #141414;
  font-family: "neue_haas_grotesk_display65Md";
}

.community_welfare_center .community_heading span,
.residential_food_forest .residential_heading span {
  color: #141414;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'neue_haas_grotesk_disp55roman';
  font-size: 12px;
}

.community_welfare_center .community_heading {
  margin-bottom: 30px;
}

.community_welfare_center h4 {
  font-family: "neue_haas_grotesk_disp55roman";
  color: #141414;
  padding-right: 30px;
}

.community_welfare_center p {
  font-family: "neue_haas_grotesk_disp55roman";
  color: #141414;
  padding-right: 100px;
}

.community_welfare_center img {
  margin-bottom: 50px;
}

/* End Community Welfare Center Portion CSS Code Here */