/* Start 1000island Footer Portion CSS Code here  */

footer {
    background-color: #141414;
    padding: 130px 0px;
}

footer ul {
    padding-inline-start: 0px;
    list-style: none;
    margin-top: 30px;
}

footer ul li a {
    letter-spacing: 1.25px;
    color: white;
    font-family: "neue_haas_grotesk_display45Lt";
}

footer ul li a:hover {
    color: white;
    text-decoration: none;
}

footer h6 {
    color: white;
    font-family: "neue_haas_grotesk_disp55roman";
    text-transform: uppercase !important;
}

footer .footer_right h6 {
    text-transform: inherit !important;
    letter-spacing: 1.5px;
}

footer .footer_right .getittouch {
    /* margin-top: 30px; */
    margin-top: 65px;
}

footer .footer_right .getittouch a {
    color: white;
}

footer .footer_right .footer_address {
    display: flex;
}

footer .footer_right .footer_address .footer_box .f_link {
    display: block;
    flex-wrap: nowrap;
}

footer .footer_right .footer_address .footer_box .f_link span {
    letter-spacing: 1px;
}

footer .footer_right .footer_address .footer_box .f_link p {
    letter-spacing: 1px;
    margin-bottom: 0px;
}

footer .footer_right .footer_address .footer_box {
    /* margin-top: 100px; */
    margin-top: auto;
}

footer .footer_right .footer_address .footer_box span {
    color: white;
    font-family: "neue_haas_grotesk_display45Lt";
}

footer .footer_right .footer_address .footer_box span a {
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
}

footer .footer_right .footer_box .f_center {
    margin: 15px 0px;
}

footer .footer_right .footer_box svg {
    /* width: 80px; */
    width: 70px;
    cursor: pointer;
}

footer .footer_right .footer_box.ml-auto-logo {
    margin-left: auto;
}


/* End 1000island Footer Portion CSS Code here  */