/* Start Award List Portion CSS Code Here */
.award_list .card {
    border: 0;
}

.award_list .card .card-title {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
    font-size: 14px;
    letter-spacing: 2.2px;
    text-align: center;
}

.award_list {
    margin: 100px 0px;
}

/* End Award List Portion CSS Code Here */