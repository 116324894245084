
/* Start Home Page Portion Responsive Media Query CSS Code Here */
@media only screen and (max-width:991px){
    .som_wellbeing .som_wellbeing_box .som_box .som_inner{
        padding: 25px;
    }
    .som_tab_box .som_tab .som_tab_content h2{
        font-size: 20px;
    }
}
@media only screen and (max-width:767px){
    .top_island_logo_text {
        margin-bottom: 90px;
    }
    .top_island_logo_text .circle_animate{
        margin-top: 50px;
    }
    .top_island_logo_text .hospitalitylogo{
        height: 100px;
        width: 100px;
    }
    .som_wellbeing .som_wellbeing_box .som_box{
        max-width: 100%;
        flex: 100%;
    }
    .som_wellbeing .som_wellbeing_box{
        flex-direction: column;
        padding-bottom: 0;
    }
    .som_wellbeing .som_wellbeing_box .somlogo{
        max-width: 60px;
    }
    .som_tab_box .som_tab{
        padding-top: 0;
        padding-bottom: 0;
    }
    .som_tab_box .som_tab .som_tab_content{
        max-width: 100%;
        flex: 100%;
        padding-bottom: 25px;
    }
    .som_tab_box .som_tab .som_tab_content::after{
        bottom: 0;
       width: 100%;
       height: 1px;
       top: 100%;
    }
    .som_tab_box .som_heading p{
        max-width: 100%;
    }
    .som_wellbeing{
        margin: 50px 0px;
    }
    .som_wellbeing .som_wellbeing_box .som_box .som_inner {
       padding-left: 0px;
       padding-right: 0px;
    }
    .som_tab_box .som_tab .som_tab_content{
        padding-left: 15px;
        padding-right: 15px;
    }
}
/* End Home Page Portion Responsive Media Query CSS Code Here */
