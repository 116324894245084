/* Start About Us Portion Responsive Media Query CSS Code Here */
@media only screen and (max-width: 991px) {
  .islanders_team h4 {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .islanders_team p {
    padding-right: 0;
  }

  .community_section h4 {
    padding-right: 0px;
    margin-bottom: 30px;
  }

  .community_section p {
    padding-right: 0px;
  }

  .pustakghar .pustakghar_box {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .about_header_banner {
    margin-top: 145px;
    margin-bottom: 65px;
  }

  .about_header_banner .hospitalitylogo {
    height: 100px;
    width: 100px;
  }

  .about_header_banner .circle_animate {
    margin-top: 25px;
  }

  .islanders_team .team_heading {
    margin: 45px 0;
  }

  .community_section .community_heading {
    margin: 45px 0;
  }

  .regenerative_section {
    margin-bottom: 45px;
  }

  .regenerative_section .regenerative_heading {
    margin: 45px 0px;
  }

  .regenerative_section h4 {
    padding-right: 0;
  }

  .regenerative_section p {
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .islanders_team img.last {
    margin-bottom: 0px;
  }

  .islanders_team img.first {
    margin-bottom: 30px;
  }

}

/* End About Us Portion Responsive Media Query CSS Code Here */