/* Start 1000island Footer Portion Responsive Media Query CSS Code here */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    footer .footer_right .footer_address .footer_box {
        /* margin-top: 50px; */
        margin-top: auto;
    }
    footer .footer_right .footer_box svg {
        width: 60px;
    }
}

@media only screen and (max-width: 767px) {
    footer .footer_right .footer_address .footer_box {
        margin-top: 30px;
    }
    footer .footer_right .footer_box svg {
        width: 60px;
    }
    footer .footer_right .footer_address {
        flex-direction: column;
    }
    footer .footer_right .footer_box.ml-auto-logo {
        margin: auto;
        margin-top: 30px;
    }
    footer .footer_right .getittouch {
        margin-top: 20px;
    }
}


/* End 1000island Footer Portion Responsive Media Query CSS Code here */