/* Start Destination First Carousel Portion CSS Code Here */
.destination_slider {
  margin-top: 150px;
  margin-bottom: 150px;
}
.destination_slider .destination_heading h1 {
  font-family: "neue_haas_grotesk_display65Md";
  margin: 0;
}
.destination_slider .destination_heading .sub_heading {
  font-family: "neue_haas_grotesk_disp55roman";
}
.destination_slider .owl-carousel {
  margin-top: 30px;
}
.destination_slider .owl-theme .owl-dots .owl-dot.active span {
  background-color: #141414;
}
.destination_slider .owl-theme .owl-dots .owl-dot span {
  background-color: #1414148f;
}
.destination_slider .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}
/* End Destination First Carousel Portion CSS Code Here */
