/* Start Gallery Slider Portion CSS Code Here */
  .gallery_heading h1 {
    color: #000000;
    font-family: "neue_haas_grotesk_display65Md";
    text-transform: capitalize;
    margin-bottom: 30px;
  }
  .gallery_slider {
    padding: 0px 80px;
  }
  .gallery_slider .owl-nav {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .gallery_slider .owl-nav .owl-next span , .gallery_slider .owl-nav .owl-prev span {
    opacity: 0;
  }
  .gallery_slider .owl-nav .owl-next:focus,
  .gallery_slider .owl-nav .owl-prev:focus {
    outline: 0;
  }
  .gallery_slider .owl-nav .owl-next {
    cursor: url(https://i.ibb.co/PtH8dC5/iconfinder-right-arrow-476325.png) 32 32,
      auto;
    height: 100%;
    width: 50%;
  }
  .gallery_slider .owl-nav .owl-prev {
    cursor: url(https://i.ibb.co/jh8Y5f5/iconfinder-left-arrow-476327.png) 32 32,
      auto;
    height: 100%;
    width: 50%;
  }
  .gallery_slider {
    margin-bottom: 130px;
  }
  /* End Gallery Slider Portion CSS Code Here */
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* Start Gallery Slider Responsive Media Query CSS Code Here  */
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .gallery_slider {
      padding: 0px 35px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1230px) {
    .gallery_slider {
      padding: 0px 35px;
    }
    .gallery_slider .container-fluid {
      padding: 0;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .gallery_slider {
      padding: 0px 25px;
    }
    .gallery_slider .container-fluid {
      padding: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .gallery_slider {
      padding: 0px 15px;
      margin-bottom: 60px;
    }
    .gallery_slider .container-fluid {
      padding: 0;
    }
    .gallery_heading {
      margin-top: 65px;
  }
  }
  /* End Gallery Slider Responsive Media Query CSS Code Here  */
  