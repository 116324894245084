/* Start Responsive Layout All Pages Portion Responsive Media Query CSS Code Here  */
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1075px !important;
  }
  .container-fluid {
    max-width: 1260px !important;
  }
}

/* End Responsive Layout All Pages Portion Responsive Media Query CSS Code Here  */
