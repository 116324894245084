/* Start Home Page Portion CSS Code Here  */
.island_banner img {
  width: 100%;
}

.island_banner {
  margin-top: 150px;
}

/* End Home Page Portion CSS Code Here  */

/* Start Top 1000island Logo & Text Portion Code Here  */
.top_island_logo_text {
  margin-top: 65px;
  margin-bottom: 140px;
}

.top_island_logo_text .hospitalitylogo {
  height: 150px;
  width: 150px;
  margin: auto;
  border-radius: 100%;
  animation: circle 15s linear infinite;
}

.top_island_logo_text .hospitalitylogo img {
  width: 100%;
}

.top_island_logo_text .circle_animate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.top_island_logo_text h3 {
  color: #141414;
  font-family: "neue_haas_grotesk_disp55roman";
}

.top_island_logo_text h3:first-child {
  margin-bottom: 30px;
}

.read_more_btn {
  background-color: transparent;
  color: #141414;
  border-color: #141414;
  border-radius: 50px;
  text-transform: uppercase;
  font-family: "neue_haas_grotesk_display65Md";
  font-size: 14px;
  text-align: left;
  width: 210px;
  position: relative;
  padding: 4px 2rem;
  /* padding: 4px 0.75rem; */
  margin-top: 30px;
}

.read_more_btn:hover {
  font-size: 15px;
}

.read_more_btn span {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  position: absolute;
  top: 42%;
  left: -3px;
  background-color: #141414;
}

.read_more_btn:hover,
.read_more_btn:focus,
.read_more_btn:active,
.read_more_btn:focus-visible {
  background-color: transparent !important;
  color: #141414 !important;
  border-color: #141414 !important;
  box-shadow: none !important;
  outline: 0;
}

/* End Top 1000island Logo & Text Portion Code Here  */

/* Start Som Wellbeing Portion CSS Code Here */
.som_wellbeing {
  margin: 140px 0px;
}

.som_wellbeing h4 {
  font-family: "neue_haas_grotesk_display65Md";
  margin-bottom: 40px;
}

.som_wellbeing .som_wellbeing_box {
  border: 1px solid #141414;
  padding: 10px;
}

.som_wellbeing .som_wellbeing_box .somlogo {
  max-width: 80px;
  width: 100%;
  cursor: pointer;
}

.som_wellbeing .som_wellbeing_box .somlogo img {
  width: 100%;
}

.som_wellbeing .som_wellbeing_box img {
  width: 100%;
}

.som_wellbeing .som_wellbeing_box {
  display: flex;
  flex-wrap: wrap;
}

.som_wellbeing .som_wellbeing_box .som_box {
  flex: 0 0 50%;
  max-width: 50%;
  cursor: pointer;
}

.som_wellbeing .som_wellbeing_box .som_box .som_inner {
  padding: 50px 140px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.som_wellbeing .som_wellbeing_box .som_box .som_inner h3 {
  color: #000000;
  font-family: "neue_haas_grotesk_display65Md";
  margin: 30px 0px;
}

.som_wellbeing .som_wellbeing_box .som_box .som_inner p {
  color: #000000;
  font-family: "neue_haas_grotesk_disp55roman";
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #4a4a4a;
  display: -webkit-box !important;
  overflow: hidden;
}

/* End Som Wellbeing Portion CSS Code Here */

/* Start Som Tab Box Portion CSS Code Here */
.som_tab_box .som_tab {
  background-color: #141414;
  color: white;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 30px;
  margin-bottom: 60px;
}

.som_tab_box .som_tab .som_tab_content {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 25px;

}

.som_tab_box .som_tab .som_tab_content h2 {
  cursor: pointer;
}

.som_tab_box .som_tab .som_tab_content h2:hover {
  font-size: 33px;
}

.som_tab_box .som_tab .som_tab_content::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
}

.som_tab_box .som_tab .som_tab_content:last-child:after {
  display: none;
}

.som_tab_box .som_tab .som_tab_content p {
  margin-bottom: 55px;
  font-family: 'neue_haas_grotesk_display35Th';
  letter-spacing: 0.5px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.som_tab_box .som_tab .som_tab_content h2 {
  font-family: 'neue_haas_grotesk_disp55roman';
}

.som_tab_box .som_tab .som_tab_content span {
  font-family: 'neue_haas_grotesk_display35Th';
}

.som_tab_box .som_heading h4 {
  font-family: "neue_haas_grotesk_display65Md";
  margin-bottom: 40px;
  color: #141414;
}

.som_tab_box .som_heading p {
  color: #141414;
  font-family: 'neue_haas_grotesk_disp55roman';
  max-width: 305px;
  width: 100%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

/* End Som Tab Box Portion CSS Code Here */