/* Start About Us Portion CSS Code Here  */

.about_header_banner {
    margin-top: 180px;
    margin-bottom: 140px;
}

.about_header_banner .hospitalitylogo {
    height: 150px;
    width: 150px;
    margin: auto;
    border-radius: 100%;
    animation: circle 15s linear infinite;
}

.about_header_banner .hospitalitylogo img {
    width: 100%;
}

.about_header_banner .circle_animate {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

@keyframes circle {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.about_header_banner h3 {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
}

.about_header_banner .center_header {
    margin: 30px 0px;
}


/* End About Us Portion CSS Code Here  */


/* Start 1000 Islanders Portion CSS Code Here */

.islanders_team img {
    width: 100%;
}

.islanders_team .team_heading {
    /* margin: 50px 0; */
    margin-bottom: 30px;
    margin-top: 50px;
}

.islanders_team .team_heading h1 {
    font-family: "neue_haas_grotesk_display65Md";
}

.islanders_team span {
    color: #141414;
    display: inline-block;
    text-transform: uppercase;
    font-family: 'neue_haas_grotesk_disp55roman';
    font-size: 12px;
}

.islanders_team h4 {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
    padding-right: 80px;
}

.islanders_team p {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
    padding-right: 70px;
}


/* End 1000 Islanders Portion CSS Code Here */


/* Start Our Community Portion CSS Code Here  */

.community_section .community_heading {
    /* margin: 50px 0; */
    margin-bottom: 30px;
    margin-top: 100px;
}

.community_section .community_heading h1 {
    color: #141414;
    font-family: "neue_haas_grotesk_display65Md";
}

.community_section span {
    color: #141414;
    display: inline-block;
    font-family: 'neue_haas_grotesk_disp55roman';
    font-size: 12px;
    text-transform: uppercase;
}

.community_section h4 {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
    padding-right: 80px;
}

.community_section p {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
    padding-right: 70px;
}

.community_section .owl-carousel {
    margin-bottom: 50px;
}

.community_section .owl-theme .owl-dots .owl-dot.active span {
    background-color: #141414;
}

.community_section .owl-theme .owl-dots .owl-dot span {
    background-color: #1414148f;
}

.community_section .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 30px;
}


/* "End Our Community Portion CSS Code Here  */


/* Start Pustak Ghar Portion CSS Code Here */

.pustakghar {
    margin-top: 50px;
}

.pustakghar p {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
    padding-right: 70px;
}

.pustakghar p span {
    font-family: 'neue_haas_grotesk_di56romanIt';
}

.pustakghar .pustakghar_box {
    max-width: 260px;
    margin: auto;
}

.pustakghar .pustakghar_box img {
    width: 100%;
}


/* End Pustak Ghar Portion CSS Code Here */


/* Start Regenerative Tourism Portion CSS Code Here */

.regenerative_section {
    margin-bottom: 150px;
}

.regenerative_section .regenerative_heading {
    /* margin: 50px 0; */
    margin-bottom: 30px;
    margin-top: 100px;
}

.regenerative_section .regenerative_heading h1 {
    color: #141414;
    font-family: "neue_haas_grotesk_display65Md";
}

.regenerative_section span {
    color: #141414;
    display: inline-block;
    font-family: 'neue_haas_grotesk_disp55roman';
    font-size: 12px;
    text-transform: uppercase;
}

.regenerative_section h4 {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
    padding-right: 80px;
}

.regenerative_section p {
    color: #141414;
    font-family: 'neue_haas_grotesk_disp55roman';
    padding-right: 70px;
}

.regenerative_section .owl-carousel {
    margin-bottom: 50px;
}

.regenerative_section .owl-theme .owl-dots .owl-dot.active span {
    background-color: #141414;
}

.regenerative_section .owl-theme .owl-dots .owl-dot span {
    background-color: #1414148f;
}

.regenerative_section .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 30px;
}


/* End Regenerative Tourism Portion CSS Code Here */