/* Start Header Portion Code Responsive Media Query CSS  */
/* @media only screen and (min-width: 992px) {
    .mobile_search {
      display: none;
    }
    body.modal-open header.visible {
      padding-right: 17px !important;
    }
  } */
  @media only screen and (max-width: 991px) {
    header {
      padding: 15px 0px;
    }
    
    header .navbar-light .navbar-toggler:focus {
      outline: 0;
    }
    header .navbar-light .navbar-toggler {
      border: 0;
      padding: 0;
      width: 42px;
    }
    header .navbar-nav .nav-link {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
  
    .navbar-nav {
      height: 50vh;
      overflow: hidden;
      margin-top: 30px;
    }
    .scrolling-hidden {
      overflow: hidden;
      position: relative;
    }
    .scrolling-hidden::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      background-color: #2c2c2e;
      opacity: 0.6;
      z-index: 1;
    }
    header .navbar .ham {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      transition: transform 400ms;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      height: 50px;
      margin-right: -19px;
      width: 47px;
    }
    header .navbar .hamRotate.active {
      transform: rotate(45deg);
    }
    header .navbar .hamRotate180.active {
      transform: rotate(180deg);
    }
    header .navbar .line {
      fill: none;
      transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
      stroke: #313131;
      stroke-width: 5px;
      stroke-linecap: round;
    }
    header .navbar .ham1 .top {
      stroke-dasharray: 40 139;
    }
    header .navbar .ham1 .bottom {
      stroke-dasharray: 40 180;
    }
    header .navbar .ham1.active .top {
      stroke-dashoffset: -98px;
    }
    header .navbar .ham1.active .bottom {
      stroke-dashoffset: -138px;
    }
  }
  /* End Header Portion Code Responsive Media Query CSS  */
  