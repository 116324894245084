/* Start 1000island Navigation Portion CSS Code Here  */
header {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 9;
  width: 100%;
  padding: 23px 0;
  transition: all 0.5s ease-in-out;
  background-color: white;
}
header .navbar-brand svg{
  width: 45px;
}
header .island_logo {
  height: 50px;
  width: 50px;
}
header .island_logo img{
  width: 100%;
}
header.visible {
  transform: translateY(-1px);
  transition: all 0.5s ease-in-out;
}
header.hidden {
  /* transform: translateY(-83px); */
  transform:  translateY(-152px);
  transition: all 0.5s ease-in-out;
}
header .navbar-light .navbar-nav .nav-link {
  font-family: "neue_haas_grotesk_display65Md";
  padding: 10px 40px;
  font-size: 14px;
  color: #141414;
}
/* End 1000island Navigation Portion CSS Code Here  */
