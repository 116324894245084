/* Start Properties Detail Portion Responsive Media Query CSS Code Here */

@media only screen and (max-width:767px) {
    .properties_detail_box .circle_animate {
        margin-top: 27px;
    }
    .properties_detail_box .hospitalitylogo {
        height: 100px;
        width: 100px;
    }
    .properties_detail_box {
        margin-top: 145px;
        margin-bottom: 65px;
    }
}


/* End Properties Detail Portion Responsive Media Query CSS Code Here */


/* Start Residential Food Forest Responsive Media Query Code Here  */

@media only screen and (max-width:991px) {
    .residential_food_forest h4 {
        padding-right: 0;
        margin-bottom: 30px;
    }
    .residential_food_forest p {
        padding-right: 0;
    }
}

@media only screen and (max-width:767px) {
    .residential_food_forest {
        margin-bottom: 35px;
    }
    .community_welfare_center {
        margin-bottom: 140px;
    }
}


/* End Residential Food Forest Responsive Media Query Code Here  */


/* Start Community Welfare Center Responsive Media Query Code Here  */

@media only screen and (max-width: 991px) {
    .community_welfare_center h4 {
        padding-right: 0;
        margin-bottom: 30px;
    }
    .community_welfare_center p {
        padding-right: 0;
    }
    .community_welfare_center p:last-child {
        margin: 0;
    }
}

@media only screen and (max-width:767px) {
    .community_welfare_center {
        margin-bottom: 0px;
    }
    .residential_food_forest .residential_heading {
        margin-bottom: 30px;
        margin-top: 40px;
    }
}


/* End Community Welfare Center Responsive Media Query Code Here  */