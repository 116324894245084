.errorpage .error_head {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-around;
}

.errorpage .errorpage_box .msg {
    margin-bottom: 14px;
    margin-left: 20px;
    letter-spacing: 3px;
    font-size: 22px;
    color: #141414;
    font-family: 'neue_haas_grotesk_display65Md';
}

.errorpage .errorpage_box h1 {
    color: #876744;
    font-size: 100px;
    mix-blend-mode: luminosity;
    opacity: 0.6;
    margin: 0px;
}

.errorpage .errorpage_box:first-child {
    border-bottom: 2px dashed #a7a7a7;
    max-width: 348px;
    width: 100%;
    display: flex;
    align-items: end;
    padding-bottom: 17px;
}

.errorpage .error_footer p {
    font-family: 'neue_haas_grotesk_display65Md';
    font-size: 13.5px;
    letter-spacing: 2px;
    color: #141414;
}

.errorpage .error_footer .logo {
    margin-bottom: 30px;
}

.errorpage .error_footer a {
    text-decoration: none;
}

/* media query here */
@media only screen and (max-width: 767px) {

    .errorpage .errorpage_box h1 {
        font-size: 50px;
    }

    .errorpage .errorpage_box .msg {
        margin-bottom: 5px;
        font-size: 18px;
    }
}