
/* Start Properties Portion Responsive Media Query CSS Code Here  */
@media only screen and (max-width:991px){
    .properties_box .card .card_img .overlay h4 {
        font-size: 18px;
    }
    .properties_box .card .card-text {
        -webkit-line-clamp: 3;
    }
    
}
@media only screen and (max-width:767px){
    .properties_box .card {
        margin-bottom: 40px;
    }
}
/* End Properties Portion Responsive Media Query CSS Code Here  */
